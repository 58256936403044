export default {
  getProductCart: (state) => state.cart,

  getStoreDetailsAndProducts: state => cartId => {
    const findIndexOfStore = state.cart.findIndex(cartData => {
      return cartData.id === cartId;
    });
    if (findIndexOfStore > -1) {
      return state.cart[ findIndexOfStore ];
    } else {
      return null;
    }
  },

  getTotalProduct (state) {
    return state.cart.reduce((total, store) => {
      let storeProductsTotal = store.cart_products.reduce((total, product) => product.quantity + total, 0);
      return total + storeProductsTotal;
    }, 0);
  },
  getProductQuantity: state => payload => {
    const cartIndex = state.cart.findIndex(cartItem => {
      return cartItem.store.id === payload.store_id;
    }
    );
    if (cartIndex > -1) {
      const cartItemIndex = state.cart[ cartIndex ].cart_products.findIndex(item => {
        return item.id === payload.id;
      });
      if (cartItemIndex > -1) {
        return state.cart[ cartIndex ].cart_products[ cartItemIndex ].quantity;
      } else {
        return 0;
      }
    }
    return 0;
  },
  getCartSubtotal: state => products => {
    return products.reduce((total, product) => {
      return total + product.net_price * product.quantity;
    }, 0);
  },
  getCartTotal: (state, getters) => cartId => {
    const findIndexOfStore = state.cart.findIndex(cartData => {
      return cartData.id === cartId;
    });
    if (findIndexOfStore > -1) {
      const subtotal = +getters.getCartSubtotal(state.cart[ findIndexOfStore ].cart_products);
      const shipping = +state.cart[ findIndexOfStore ].delivery_cost;
      const taxAmount = +state.cart[ findIndexOfStore ].tax_amount;
      const discount = +state.cart[ findIndexOfStore ].discount_amount;
      const total = subtotal + shipping + taxAmount - discount;
      return total.toFixed(2);
    } else {
      return 0;
    }
  },
  getProductFavourite (state) {
    return state.favourite;
  },
  checkProductInFavourite: state => payload => {
    const findIndexOfProduct = state.favourite.findIndex(item => {
      return item.id === payload.id;
    });
    if (findIndexOfProduct > -1) {
      return true;
    } else {
      return false;
    }
  },
};
