const cart_product = (payload) => {
  return {
    id: payload.id,
    title: payload.title,
    price: payload.price,
    net_price: payload.net_price,
    quantity: payload.quantity,
    featured_image: payload.featured_image,
  };
};

const cartItem = (payload) => {
  return {
    id: Math.random().toString(),
    store: {
      id: payload.store_id,
      name: payload.store_name,
    },
    cart_products: [
      cart_product(payload),
    ],
  };
};

export default {
  SET_CART (state, payload) {
    state.cart = payload;
    localStorage.setItem('cart', JSON.stringify(payload));
  },
  SET_GUEST_CART (state, payload) {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    if (!cart.length) {
      cart.push(
        cartItem(payload)
      );
    }
    else {
      const storeIndex = cart.findIndex(cartItem => {
        return cartItem.store.id === payload.store_id;
      });
      if (storeIndex === -1) {
        cart.push(cartItem(payload));
      }
      else {
        const cartItemIndex = cart[ storeIndex ].cart_products.findIndex(cartItem => {
          return cartItem.id === payload.id;
        });
        if (cartItemIndex === -1) {
          cart[ storeIndex ].cart_products.push(cart_product(payload));
        }
        else {
          cart[ storeIndex ].cart_products[ cartItemIndex ].quantity = payload.quantity;
        }

      }
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    state.cart = cart;
  },

  UPDATE_LOCAL_CART (state, payload) {
    // return console.log(state, payload);
    const cartIndex = state.cart.findIndex(cart => {
      return cart.id === payload.cartId;
    });
    const cartItemIndex = state.cart[ cartIndex ].cart_products.findIndex(cartItem => {
      return cartItem.id == payload.id;
    });
    state.cart[ cartIndex ].cart_products[ cartItemIndex ].quantity = payload.quantity;
    localStorage.setItem('cart', JSON.stringify(state.cart));

  },
  UPDATE_CART (state, payload) {
    const cartIndex = state.cart.findIndex(cart => {
      return cart.id === payload.id;
    });
    state.cart.splice(cartIndex, 1, payload);
  },
  SET_SHIPPING_COMPANY (state, payload) {
    const { cartId, company } = payload;
    const cartIndex = state.cart.findIndex(cart => {
      return cart.id === cartId;
    });
    state.cart[ cartIndex ].delivery_cost = company.shipping_fees;
    state.cart[ cartIndex ].shipping_company_id = company.id;
  },
  DELETE_PRODUCT (state, { cartId, cartItemId }) {
    const cartIndex = state.cart.findIndex(cart => {
      return cart.id === cartId;
    });
    const cartItemIndex = state.cart[ cartIndex ].cart_products.findIndex(cartItem => {
      return cartItem.id === cartItemId;
    });
    state.cart[ cartIndex ].cart_products.splice(cartItemIndex, 1);
    if (!state.cart[ cartIndex ].cart_products.length) {
      state.cart.splice(cartIndex, 1);
    }
    if (!localStorage.getItem('userToken')) {
      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  },
  DELETE_STORE (state, id) {
    const cartIndex = state.cart.findIndex(cart => {
      return cart.id === id;
    });
    state.cart.splice(cartIndex, 1);
    if (!localStorage.getItem('userToken')) {
      localStorage.setItem('cart', JSON.stringify(state.cart));
    }
  },
  changeFavouriteInLocalStorage (state) {
    localStorage.setItem('favourite', JSON.stringify(state.favourite));
  },
};
