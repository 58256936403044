import { marketPlace } from '@/axios';
export default {
  getAddresses () {
    return marketPlace().get('/customer/customers-addresses');
  },
  addAddress (payload) {
    return marketPlace().post('/customer/customers-addresses', payload);
  },
  editAddress (id, payload) {
    return marketPlace().patch(`/customer/customers-addresses/${ id }`, payload);
  },
  deleteAddress (id) {
    return marketPlace().delete(`/customer/customers-addresses/${ id }`);
  },
};