import Vue from 'vue';
import Vuex from 'vuex';
import Setting from './Setting/index';
import cart from './cart';
import address from './address';
Vue.use(Vuex);


export default new Vuex.Store({
  modules: {
    Setting,
    cart,
    address
  },
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).user : null
  },
  mutations: {
    updateUserInfo (state, data) {
      state.userInfo = data;
    }
  },
  actions: {
    setUserInfo (context, payload) {
      localStorage.setItem("userInfo", JSON.stringify({ user: payload.user }));
      localStorage.setItem("userToken", JSON.stringify(payload.token));
      context.commit('updateUserInfo', payload.user);
    }
  },
  getters: {
    getUserInfo: state => state.userInfo
  },
});
