export default {
  data () {
    return {
      transelateStatus: {
        pending: this?.$i18n?.locale === 'ar' ? 'جارى الطلب' : 'pending',
        refused: this?.$i18n?.locale === 'ar' ? 'تم الرفض' : 'refused',
        on_delivery: this?.$i18n?.locale === 'ar' ? 'جاري الشحن' : 'on delivery',
        success: this?.$i18n?.locale === 'ar' ? 'تم الاستلام' : 'success'
      }
    };
  }
};
