export default {
  discountCode: 'Discount Code',
  discountAmount: 'discount amount',
  clientName: 'client name',
  country: 'country',
  city: 'city',
  street: 'street',
  district: 'district',
  phoneNumber: 'phone number',
  addressDetails: 'address in details',
  specialSign: 'special sign',
  cardNumber: 'card number',
  monthEnd: 'end month',
  yearEnd: 'year end',
  cvv: 'CVV',
  activate: 'activate',
  addNewCreditCard: 'add new credit card',
  addNewCredit: 'add credit card',
  sure: 'Done',
  paymentOptions: 'payment options',
  address: 'address',
  area: 'area',
  zipCode: 'zip code',
  title: 'title',
  myAddress: 'My Addresses',
  addNewAddress: 'Add New Address',
  addAddress: 'Add Address',
  editAddress: 'Edit Address',
  e_wallet: 'E-wallet',
  WALLET: 'wallet',
  CARD: 'credit card',
  creditCard: 'Credit Card',
  chooseAddress: 'choose address',
  validOrder: 'confirm order',
  continueShopping: 'continue shopping',
  orderInfo: 'order details',
  tax: 'Tax',
  transportation: 'transportation',
  sum: 'Sum',
  edit: 'edit',
  change: 'change',
  balance: 'wallet balance',
  add_balance: 'Add Balance',
  password: 'password',
  add_balance_wallet: 'Add balance to the wallet',
  master_card: 'Master Card',
  balance_charge: 'The balance to be charged',
  charge: 'charge',
  withdraw_amount: 'The balance to be withdrawn',
  withdraw: 'withdraw',
  withdraw_request: 'withdraw request',
  setting: 'wallet settings',
  pin_code: 'PIN code',
  total: 'Total',
  current_pin_code: 'Current PIN code',
  new_pin_code: 'Enter your new code',
  confirm_pin_code: 'Confirm new code',
  forget_pin: 'Forgot your PIN code',
  to_forget: 'Forget the password of the wallet',
  send_code: 'send code',
  successDiscount: 'Discount code has been successfully applied',
  shippingCampanies: 'Shipping Companies',
  delivery_cost: 'Delivery Cost',
};
