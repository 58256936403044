<template>
  <b-form-group :label="label" :label-for="name" class="custom-label">
    <validation-provider
      #default="{ errors }"
      :name="name"
      :rules="validate"
      :vid="vid"
      mode="eager"
    >
      <div class="input-wrapper position-relative">
        <span class="prepended-item">
          <slot name="prepend">
            <!-- <i class="fas fa-user"></i> -->
          </slot>
        </span>
        <input
          :id="id"
          :disabled="disabled"
          :type="type"
          :value="value"
          :placeholder="placeholder"
          @input="$emit('input', $event.target.value)"
        />
        <span class="appended-item">
          <slot name="append"> </slot>
        </span>
      </div>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
export default {
  props: {
    /* errors: {
      type: Object
    }, */

    label: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    type: {
      default: "text",
    },
    id: {
      default: "",
      type: String,
    },
    validate: {
      default: "",
      required: false,
    },
    value: {
      default: "",
    },
    placeholder: {
      default: "",
      type: String,
    },
    disabled: {
      default: false,
    },
    vid: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      inMarketPlace: false,
    };
  },
  methods: {
    test(t, y) {
      console.log(t, y);
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.inMarketPlace =
          route.path.includes("user-profile/user-marketplace") ||
          route.path.includes("join/") ||
          route.path.includes("payment-actions/");
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.form-control {
  all: unset !important;
}

.input-wrapper {
  width: 100%;
  background: #f2f2f2;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  border-radius: 4px;
  padding: 0.5rem;

  input {
    background: transparent;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
.order-label,
.join-form {
  .input-wrapper {
    background: #fff !important;
  }
}
</style>
