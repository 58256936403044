import addressService from '../../services/address';
const state = () => ({
  address: {},
  addressesList: [],
  selectedAddress: null,
  userCoordinates: {
    lat: null,
    lng: null
  },
});

const mutations = {
  SET_ADDRESSES (state, payload) {
    state.addressesList = payload;
    !state.selectedAddress && (state.selectedAddress = payload[ 0 ].id);
  },
  ADD_ADDRESS (state, payload) {
    state.addressesList.push(payload);
  },
  EDIT_ADDRESS (state, payload) {
    const index = state.addressesList.findIndex(address => address.id === payload.id);
    state.addressesList.splice(index, 1, payload);
  },
  DELETE_ADDRESS (state, id) {
    const index = state.addressesList.findIndex(address => address.id === id);
    state.addressesList.splice(index, 1);
  },
  SET_SELECTED_ADDRESS (state, payload) {
    state.selectedAddress = payload;
  },
  UPDATE_USER_COORDINATES (state, payload) {
    state.userCoordinates = payload;
  },
};

const actions = {
  async getUserCoordinates ({ commit }) {
    const geo = navigator.geolocation;
    return new Promise((resolve, reject) => {
      if (!geo) {
        reject(new Error('Not Supported'));
      }
      geo.getCurrentPosition((position) => {
        const userCoordinates = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        commit('UPDATE_USER_COORDINATES', userCoordinates);
        resolve(userCoordinates);
      }, () => {
        reject(new Error('Permission denied, please allow location access'));
      });
    });
  },
  async getAddresses ({ commit, state }) {
    try {
      const { data } = await addressService.getAddresses();
      commit('SET_ADDRESSES', data.data);
      return data;
    } catch (error) {
      throw error;
    }
  },
  async addAddress ({ dispatch }, payload) {
    try {
      const res = await addressService.addAddress(payload);
      await dispatch('getAddresses');
      return res;
    } catch (error) {
      throw error;
    }
  },
  async editAddress ({ commit }, payload) {
    try {
      const res = await addressService.editAddress(payload.id, payload);
      commit('EDIT_ADDRESS', payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
  async deleteAddress ({ commit }, id) {
    try {
      const res = await addressService.deleteAddress(id);
      commit('DELETE_ADDRESS', id);
      return res;
    } catch (error) {
      throw error;
    }
  },
};

const getters = {
  userCoordinates: state => state.userCoordinates,
  addressesList: state => state.addressesList,
  selectedAddress: state => state.selectedAddress,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
};