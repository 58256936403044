export default {
  joinUsSeller: 'انضم كتاجر',
  joinAsSellerParagraph: 'انضم لسوق العطارة واحصل على متجرك الإلكتروني',
  IdNumber: 'رقم الهوية',
  IdNumberWithCondition: 'رقم الهوية يبدأ 1 او 2',
  password: 'الرقم السرى',
  newPassword: 'الرقم السرى الجديد',
  passwordChecked: 'تأكيد الرقم السرى',
  currentPassword: 'الرقم السرى الحالى',
  changePassword: 'تغير الرقم السرى',
  phoneNumber: 'الهاتف',
  countryCode: 'رمز الدولة',
  userName: 'الاسم',
  fullName: 'الاسم بالكامل',
  email: 'البريد الإلكترونى',
  address: 'العنوان',
  otbCode: 'الرمز',
  check: 'تحقق',
  enter: 'دخول',
  subject: 'عنوان الرسالة',
  enterAccount: 'تسجيل الدخول ؟',
  createNewAccount: ' تسجيل حساب جديد ؟',
  createAccount: ' تسجيل حساب',
  codeOtb: 'كود الرمز',
  otbTitle: 'تم إرسال رسالة إلى البريد الألكتروني الخاص بكم',
  otbInfo: 'تتضمن رمز OTP',
  continueOtb: 'المتابعة',
  create: 'تسجيل ',
  logIn: 'تسجيل دخول ',
  logout: 'تسجيل خروج',
  forgetPassword: 'نسيت كلمة السر ؟',
  send: 'ارسال',
  connectWith: 'تواصل معنا',
  messageText: 'نص الرسالة"',
  aboutUs: 'معلومات عنا',
  save: 'حفظ',
  userInformation: 'بينات المستخدم',
  change: 'تعديل',
  resetPassword: 'إعادة تعيين كلمة المرور',
  resetPasswordContent: 'لنسيان كلمة المرور ، سنرسل لك رمزًا إلى بريدك الألكتروني لإعادة تعيين كلمة المرور',
  secondary_phone_number: 'رقم هاتف اخر',
  back_home: 'الرجوع للصفحه الرئيسية',
  acceptTerms: 'أوافق على الشروط والأحكام',
  termsConditions: 'الشروط والأحكام',
  userImage: 'صورة الملف الشخصي',
};
